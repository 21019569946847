import { Col, Modal, Row, Tabs, TabsProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { DataType } from '..'
import IconCancel from './IconCancel'
import ModalHistory from './ModalHistory'

interface IProps {
  isModalOpen: boolean
  bill: DataType | undefined
  setIsModalOpen: (value: boolean) => void
}

const ModalDetail = ({ isModalOpen, setIsModalOpen, bill }: IProps) => {
  const keys = {
    name: 'Số phiếu:',
    status: 'Trạng thái:',
    loaiThu: 'Loại thu:',
    noidung: 'Nội dung:',
    hocKy: 'Học kỳ:',
    kenh_thu: 'Kênh đóng:',
    tongThu: 'Tổng thu (VND):',
    mienGiam: 'Miễn giảm (VND):',
    phaiThu: 'Phải thu (VND):',
    ngay_tao: 'Ngày tạo:',
    ngay_thu: 'Ngày thu:',
    date_line: 'Ngày đến hạn:',
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const detailBill = () => (
    <>
      <div className="bg-[#F3F7FC] rounded-[8px] p-[10px] w-full">
        {bill &&
          Object.keys(keys).map((e, index) => (
            <Row
              gutter={{ xs: 12, sm: 16, md: 24, lg: 32 }}
              key={index}
              className="flex justify-between border-b-2 border-[#E9E9E9]"
            >
              <Col className="gutter-row mt-2" key={e} md={{ span: 8 }} xs={{ span: 12 }}>
                <div className="text-[#6B6B6B] font-[400] text-[16px]">{keys[e]}</div>
              </Col>
              <Col xs={{ span: 12 }} className="mt-2">
                <div className="min-h-[28px] text-[#151515] font-[400] text-[16px] text-right">
                  <div dangerouslySetInnerHTML={{ __html: bill[e] }} />
                  {/* {e === 'ngay_tao' || e === 'date_line' || e === 'ngay_thu' ? (
                    bill[e].split(' ')[1] // get date in string datetime response "HH:mm:ss DD/MM/YYYY"
                  ) : (
                  )} */}
                </div>
              </Col>
            </Row>
          ))}
        <div className="text-[#6B6B6B] font-[400] text-[16px] mt-3">Chi tiết:</div>
        <div
          className="text-[#151515] font-[400] text-[16px] border-b-2 border-[#E9E9E9]"
          dangerouslySetInnerHTML={{ __html: bill?.chi_tiet }}
        />
      </div>
    </>
  )

  const paymentHistory = () => {
    return <ModalHistory bill={bill} isModalOpen={isModalOpen} />
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Chi tiết phiếu thu',
      children: detailBill(),
    },
    {
      key: '2',
      label: 'Lịch sử giao dịch',
      children: paymentHistory(),
    },
  ]

  const operations = <IconCancel handleCancel={handleCancel} />

  const [tabActive, setTabActive] = useState('1')

  useEffect(() => {
    setTabActive('1')
  }, [isModalOpen])

  return (
    <>
      <Modal
        title={<></>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<></>}
        className="max-w-[1000px] rounded-[6px] p-0"
        width={'90%'}
      >
        <div className="flex justify-between items-center">
          <Tabs
            activeKey={tabActive}
            items={items}
            tabBarExtraContent={operations}
            className="tabActive"
            type="card"
            onChange={(e) => setTabActive(e)}
          />
        </div>
      </Modal>
    </>
  )
}

export default ModalDetail
