import { detectEnvUat } from '@utils/general'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setIsHome } from 'store/appSlice'
import { IEdubillDetail } from '../../../types/general'

interface P {
  item: IEdubillDetail | any
}

const SchoolItem = ({ item }: P) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <div
      className={`rounded-[16px] shadow max-w-[399px] flex px-[45px] py-[20px] justify-center items-center gap-[10px] flex-col w-[90%] md:min-h-[355px] 
      mx-auto md:mx-0 border-[8px] relative
      ${!item?.comingSoon ? 'border-[#fff] bg-white cursor-pointer' : 'border-[#edeaea] bg-[#edeaea] cursor-default'}`}
      style={{ boxShadow: '0px 4px 54px 0px rgba(0, 0, 0, 0.10)' }}
      onClick={() => {
        if (!item?.comingSoon) {
          navigate(`${detectEnvUat() ? '/uat' : ''}/search/${item?.orgCode}/school/${item?.schoolCode}`)
          dispatch(setIsHome(false))
        }
      }}
    >
      {item?.comingSoon && <p className="comingSoon text-[#595959] text-[16px] font-[500]">Coming Soon</p>}
      <div
        className="md:text-[22px] text-[16px] text-[#06163A] font-bold border-b-2 border-[#045993] pb-4 text-center"
        dangerouslySetInnerHTML={{
          __html: item?.schoolName,
        }}
      />
      <div className="flex justify-center items-center mt-4">
        <img
          src={!item?.comingSoon ? item?.avatarUrl : require('@assets/background/logo.png')}
          alt=""
          className="w-[76px] h-[76px] md:h-[156px] md:w-[156px] object-contain"
        />
      </div>
    </div>
  )
}

export default SchoolItem
