import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { ResponseBase } from '../types/general'
import {
  CODE_SUCCESS,
  CODE_TIME_OUT,
  ERROR_CODE,
  ERROR_NETWORK_CODE,
  RESULT_CODE_PUSH_OUT,
  STATUS_TIME_OUT,
  TIME_OUT,
} from './constan'

export const API_URL = process.env.REACT_APP_BASE_API

export interface ParamsNetwork extends AxiosRequestConfig {
  url: string
  params?: Record<string, string | number>
  path?: Record<string, string | number>
  body?: Record<string, unknown>
  controller?: AbortController
}

const defaultConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  timeout: TIME_OUT,
}

const responseDefault: ResponseBase<Record<string, unknown>> = {
  code: -500,
  status: false,
  msg: ERROR_CODE.have_error,
}

const AxiosInstance = Axios.create(defaultConfig)

AxiosInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config
    if (
      error &&
      error.response &&
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      return AxiosInstance(originalRequest)
    }
    return Promise.reject(error)
  },
)

export const handleResponseAxios = <T = Record<string, unknown>>(res: AxiosResponse<T>): ResponseBase<T> => {
  if (res.data) {
    return { code: CODE_SUCCESS, status: true, data: res.data }
  }
  return responseDefault as ResponseBase<T>
}

export const handleErrorApi = (status: number) => {
  const result = { status: false, code: status, msg: '' }
  if (status >= 500) {
    result.msg = ERROR_CODE.server_error
    return result
  }
  if (status < 500 && status >= 418) {
    result.msg = ERROR_CODE.error_on_request
    return result
  }
  result.msg = ERROR_CODE[status]
  return result
}

export const handleErrorAxios = <T = Record<string, unknown>>(error: AxiosError): ResponseBase<T> => {
  if (error.code === STATUS_TIME_OUT) {
    // timeout
    return handleErrorApi(CODE_TIME_OUT) as unknown as ResponseBase<T>
  }
  if (error.response) {
    if (error.response.status === RESULT_CODE_PUSH_OUT) {
      return handleErrorApi(RESULT_CODE_PUSH_OUT) as unknown as ResponseBase<T>
    } else {
      return handleErrorApi(error.response.status) as unknown as ResponseBase<T>
    }
  }
  return handleErrorApi(ERROR_NETWORK_CODE) as unknown as ResponseBase<T>
}

export const handlePath = (url: string, path: ParamsNetwork['path']) => {
  if (!path || Object.keys(path).length <= 0) {
    return url
  }
  let resUrl = url
  Object.keys(path).forEach((k) => {
    resUrl = resUrl.replaceAll(`{${k}}`, String(path[k]))
    resUrl = resUrl.replaceAll(`:${k}`, String(path[k]))
  })
  return resUrl
}

export const handleParameter = <T extends ParamsNetwork>(props: T, method: Method): ParamsNetwork => {
  const { url, body, path, params } = props
  return {
    ...props,
    method,
    url: handlePath(url, path),
    data: body,
    params,
  }
}

export const validResponse = (response: ResponseBase<any>): response is ResponseBase<any, true> => {
  if (!response.status) {
    // TODO: handle error
    return false
  }
  return true
}

// base
function Request<T = Record<string, unknown>>(config: ParamsNetwork) {
  return new Promise<ResponseBase<T> | null>((rs) => {
    AxiosInstance.request(config)
      .then((res: AxiosResponse<T>) => {
        const result = handleResponseAxios(res)
        rs(result)
      })
      .catch((error: AxiosError<T>) => {
        if (error.code === AxiosError.ERR_CANCELED) {
          rs(null)
        }
        const result = handleErrorAxios(error)

        if (result.code === RESULT_CODE_PUSH_OUT) {
          rs(null)
        } else {
          rs(result as ResponseBase<T>)
        }
      })
  })
}

// get
async function Get<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, 'GET'))
}

// post
async function Post<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, 'POST'))
}

export const NetWorkService = {
  Get,
  Post,
  Request,
}
