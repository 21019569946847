import { detectEnvUat } from '@utils/general'
import CryptoJS from 'crypto-js'
import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { io } from 'socket.io-client'
import { setTrasnSocketNotify } from 'store/appSlice'
import { EmitTrasnSocketNotify } from '../types/general'

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
const SECRET_KEY_SOCKET = process.env.REACT_APP_SECRET_KEY_SOCKET

interface SocketCallingProps {
  transactionCode: string
}

const SocketCalling = ({ transactionCode }: SocketCallingProps) => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const generateChecksum = () => {
    // Using SHA-256 algorithm
    const hash = CryptoJS.SHA256(`${SECRET_KEY_SOCKET}|${params.orgCode}|${params.schoolCode}`)

    // Convert the hash to a hexadecimal string
    const calculatedChecksum = hash.toString(CryptoJS.enc.Hex)

    return {
      hash: calculatedChecksum,
    }
  }

  useEffect(() => {
    const client = io(SOCKET_URL, {
      transports: ['websocket'],

      auth: {
        orgcode: params.orgCode,
        schoolcode: params.schoolCode,
        checksum: generateChecksum().hash,
      },
    })

    client.on('TRANS_NOTIFY', (data: EmitTrasnSocketNotify | undefined) => {
      if (data && transactionCode === data.context.trasactionId) {
        const returnUrl = `${detectEnvUat() ? '/uat' : ''}/payment-result?status=${
          data.context.success ? 'success' : 'fail'
        }&orgCode=${params.orgCode}&schoolCode=${params.schoolCode}&secretCode=${params.studentID}`
        dispatch(setTrasnSocketNotify(data))
        navigate(returnUrl)
      }
    })

    client.on('connect_error', (err) => {
      console.log(err, 'this is error')
      console.log(`connect_error due to ${err.message}`)
    })

    return () => {
      client.disconnect()
      client.offAny()
      // updateJob?.cancel();
    }

    // eslint-disable-next-line
  }, [transactionCode])

  return null
}

export default memo(SocketCalling)
