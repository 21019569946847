import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faLocationDot, faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Type_Initstate } from 'store/appSlice'

const Footer = () => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app)
  const { edubillDetail } = appState

  return (
    <>
      {!appState.isHome && (
        <div className="px-12 py-6 bg-[#212327] items-end">
          <div
            className="flex items-center pb-6"
            style={{
              borderBottom: '2px solid rgba(255, 255, 255, 0.15)',
            }}
          >
            <img src={edubillDetail?.avatarUrl} alt="" className="w-[76px] h-[76px]" />
            <span
              className="text-[#fff] md:text-[22px] font-[700] pl-5 text-[16px]"
              dangerouslySetInnerHTML={{
                __html: edubillDetail?.schoolName,
              }}
            />
          </div>
          <p className="text-[#fff] text-[16px] md:text-[20px] font-[500] py-12 ">LIÊN HỆ</p>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col pr-6">
              <p
                className="text-[#ffffffbf] text-[15px] pb-4"
                dangerouslySetInnerHTML={{
                  __html: edubillDetail?.schoolName,
                }}
              />
              <div className="flex flex-col md:pt-0 pt-5">
                <p className="text-[#ffffffbf] text-[15px] pb-4">
                  <FontAwesomeIcon icon={faLocationDot} /> Địa chỉ: {edubillDetail?.address}
                </p>
                {/* <p className="text-[#ffffffbf] text-[15px]">
                <FontAwesomeIcon icon={faFax} /> Fax: {edubillDetail?.fax}
              </p> */}
              </div>
              <p className="text-[#ffffffbf] text-[15px] pb-4">
                <FontAwesomeIcon icon={faPhoneFlip} /> Điện thoại: {edubillDetail?.phone}
              </p>
              <p className="text-[#ffffffbf] text-[15px]">
                <FontAwesomeIcon icon={faEnvelope} /> Email: {edubillDetail?.email}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default Footer
