// import { listSchool } from '@utils/constan'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setEduBillDetail, setIsHome, setStudenInfor, Type_Initstate } from 'store/appSlice'
import SchoolItem from './components/SchoolItem'

const Home = () => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app)
  const { listEdubillDetail } = appState

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setStudenInfor(undefined))
    dispatch(setEduBillDetail(undefined))
    dispatch(setIsHome(true))
  }, [])

  return (
    <div>
      <div className="md:text-[22px] text-[16px] font-bold text-center md:mt-[10vh] mt-[4vh] text-[#045993]">
        Chọn trường
      </div>
      <div className="md:text-[18px] text-[16px] text-center mt-3">
        Vui lòng chọn trường để tiếp tục tra cứu thông tin học phí
      </div>
      <div className="flex justify-center items-start gap-[37px] self-stretch mt-10 md:flex-row flex-col">
        {/* {listSchool.map((e, i) => (
          <SchoolItem item={e} key={i} />
        ))} */}

        {[...listEdubillDetail].map((e, i) => (
          <SchoolItem item={e} key={i} />
        ))}
      </div>
    </div>
  )
}
export default Home
