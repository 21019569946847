import { formatMoney } from '@utils/general'
import { Col, Row } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'

function DetailItemHistory(props: any) {
  const { item, keys } = props

  const [hidenField, setHidenField] = useState(true)

  const convertStatus = (status: number | string) => {
    return Number(status) === 0 ? 'Chưa thu' : Number(status) === 1 ? 'Đã thu' : 'Đang thanh toán'
  }

  const convertNumber = (value: number | string) => {
    return formatMoney(parseFloat(`${value}`)) + ''
  }

  const subKeys = {
    so_phieu_bao: 'Số phiếu thu',
    noi_dung: 'Nội dung',
  }

  return (
    <>
      {Object.keys(keys).map((e, index) => (
        <>
          <Row
            gutter={{ xs: 12, sm: 16, md: 24, lg: 32 }}
            className="flex justify-between border-b-2 border-[#E9E9E9]"
            key={index}
          >
            <Col className="gutter-row mt-2" key={e} md={{ span: 8 }} xs={{ span: 10 }}>
              <div className="text-[#6B6B6B] font-[400] text-[16px]">{keys[e]}</div>
            </Col>
            <Col xs={{ span: 14 }} className="mt-2">
              <div className="min-h-[28px] text-[#151515] font-[400] text-[16px] text-right">
                <span className={`${e === 'TransactionNo' ? 'font-extrabold' : ''}`}>
                  {e === 'status'
                    ? convertStatus(item[e])
                    : e === 'amount'
                    ? convertNumber(item[e])
                    : e === 'createDate'
                    ? moment(item[e]).format('HH:mm:ss DD/MM/YYYY')
                    : item[e]}
                </span>
              </div>
            </Col>
          </Row>
        </>
      ))}
      {hidenField ? (
        <span className="text-[#3d789b] underline pt-2 block" onClick={() => setHidenField(false)}>
          Xem chi tiết
        </span>
      ) : (
        <>
          {Object.keys(subKeys).map((e, index) => (
            <Row
              gutter={{ xs: 12, sm: 16, md: 24, lg: 32 }}
              className="flex justify-between border-b-2 border-[#E9E9E9]"
              key={index}
            >
              <Col className="gutter-row mt-2" key={e} md={{ span: 8 }} xs={{ span: 10 }}>
                <div className="text-[#6B6B6B] font-[400] text-[16px]">{subKeys[e]}</div>
              </Col>
              <Col xs={{ span: 14 }} className="mt-2">
                <div className="min-h-[28px] text-[#151515] font-[400] text-[16px] text-right">
                  <span className={`${e === 'TransactionNo' ? 'font-extrabold' : ''}`}>
                    {e === 'status' ? convertStatus(item[e]) : e === 'amount' ? convertNumber(item[e]) : item[e]}
                  </span>
                </div>
              </Col>
            </Row>
          ))}
          <span className="text-[#3d789b] underline pt-2 block" onClick={() => setHidenField(true)}>
            Ản bớt
          </span>
        </>
      )}
    </>
  )
}

export default DetailItemHistory
