import IconCancel from '@pages/Payment/Components/IconCancel'
import { Modal } from 'antd'
import React, { FC } from 'react'

interface IProps {
  img?: string
  isModalOpen: boolean
  setIsModalOpen: (value: boolean) => void
}

const ModalACB: FC<IProps> = ({ isModalOpen, img, setIsModalOpen }: IProps) => {
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Modal
        title={<></>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<></>}
        className="p-0 custom-modal rouder-31"
      >
        <div className="relative ">
          <span className="absolute right-2 top-2">
            <IconCancel
              handleCancel={() => {
                handleCancel()
              }}
            />
          </span>
          <img src={`${img}&bg=5`} alt="" className="mx-auto w-full" />
        </div>

      </Modal>
    </>
  )
}
export default ModalACB
