import React from 'react'

const IconThuMotPhan = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Check / 24 / Enabled - selected">
        <rect id="BG" x="2" y="2" width="20" height="20" rx="10" fill="white" />
        <path id="Check" d="M9.59 14.58L6.42 11.41L5 12.82L9.59 17.41L18.59 8.41L17.18 7L9.59 14.58Z" fill="#1BA803" />
      </g>
    </svg>
  )
}

export default IconThuMotPhan
