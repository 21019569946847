import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import { setStudenInfor, Type_Initstate } from 'store/appSlice'
import IconError from './IconError'

const ErrorPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app)

  useEffect(() => {
    dispatch(setStudenInfor(undefined))
  }, [])

  return (
    <div>
      {appState.device === 'web' && (
        <p
          className="mt-4 text-[#045993] text-[16px] font-[700] text-left underline cursor-pointer"
          onClick={() => navigate('/')}
        >
          {'<<'} Trang chủ
        </p>
      )}
      <div className="mx-auto mt-20 flex justify-center items-center flex-col">
        <IconError />
        <p className="text-[#D71920] text-[20px] font-[700] mt-5">Đã có lỗi xảy ra</p>
      </div>
    </div>
  )
}

export default ErrorPage
