import { CaretRightOutlined } from '@ant-design/icons'
import useWindowDimensions from '@hooks/useWindowDimensions'
import { formatMoney } from '@utils/general'
import { Col, Empty, Row, Spin, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import moment from 'moment'
import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { DataType } from '..'
import { BillType, ITransactionHistory } from '../../../types/general'
import CellRenderStatus from './CellRenderStatus'
import IconCheck from './IconCheck'
import IconThuMotPhan from './IconThuMotPhan'
import ModalDetail from './ModalDetail'

interface IProps {
  status: number
  bills: BillType[]
  currentValue: string[]
  paymentHistorys: ITransactionHistory[]
  listSelect: string[]
  setListSelect: React.Dispatch<React.SetStateAction<string[]>>
  setimgBidv: React.Dispatch<(prevState: undefined) => undefined>
  setCurrentValue: React.Dispatch<React.SetStateAction<string[]>>
}

const TableCustom: FC<IProps> = ({
  bills,
  status,
  listSelect,
  currentValue,
  setListSelect,
  setCurrentValue,
  setimgBidv,
}: IProps) => {
  const loading1 = useSelector<RootState>((state) => state.app.loading1) as boolean
  const [isLoadingLocal, setIsLoadingLocal] = useState(true)
  const [billRender, setBillRender] = useState<DataType[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [bill, setBills] = useState<DataType | undefined>(undefined)
  const HEGHT = 36
  const { width } = useWindowDimensions()
  const isMobile = useMemo(() => width < 640, [width]) // is mobile size if width < 640
  const STATUS = {
    chua_thu: 'Chưa thu',
    da_thu: 'Đã thu',
    thu_mot_phan: 'Thu một phần',
    dang_thanh_toan: 'Đang thanh toán',
  }

  const handleConvertData = () => {
    let item: DataType | undefined = undefined
    let countPhaiThu = 0
    let countTongThu = 0
    let countDaThu = 0
    let countConNo = 0
    let mienGiam = 0

    const chi_tiet: any[] = bills.map((bill, i) => {
      countPhaiThu += +bill.phai_thu
      mienGiam += +bill.mien_giam
      countTongThu += +bill.tong_thu

      if (bill.trang_thai === 1) {
        countDaThu += parseFloat(`${bill.phai_thu - bill.mien_giam}`)
      } else {
        countConNo += parseFloat(`${bill.phai_thu - bill.mien_giam}`)
      }

      item = {
        _id: '',
        height: `${HEGHT}px`,
        key: bill.so_phieu_bao + `_${i}`,
        phaiThu: `${formatMoney(countPhaiThu)}`,
        daThu: `${formatMoney(countDaThu)}`,
        mienGiam: `${formatMoney(mienGiam)}`,
        name: '',
        noidung: '',
        status: bill.IDSV,
        ma_sv: bill.ma_sv,
        expaned: '0',
        trang_thai: '',
        tongThu: `${formatMoney(countTongThu)}`,
        conNo: `${formatMoney(countConNo)}`,
        hocKy: '',
        loaiThu: '',
        date_line: '',
        kenh_thu: '',
        ngay_tao: '',
        ngay_thu: '',
        hoc_ky_chu: '',
        chi_tiet: '',
      }

      return {
        _id: bill._id,
        height: bill.noi_dung ? `${bill.noi_dung.split('<br>').length * HEGHT}px` : `${HEGHT}px`,
        key: bill.so_phieu_bao,
        name: bill.so_phieu_bao,
        noidung: bill.noi_dung,
        qrCode: bill?.qrCode,
        status:
          bill.trang_thai === 0
            ? STATUS.chua_thu
            : bill.trang_thai === 1
            ? STATUS.da_thu
            : bill.trang_thai === 3
            ? STATUS.thu_mot_phan
            : STATUS.dang_thanh_toan,
        phaiThu: formatMoney(parseFloat(`${bill.phai_thu}`)) + '',
        daThu: bill?.trang_thai === 1 ? formatMoney(parseFloat(`${bill.phai_thu}`)) : '0',
        mienGiam: formatMoney(parseFloat(`${bill.mien_giam}`)),
        conNo: bill?.trang_thai === 0 ? formatMoney(parseFloat(`${bill.phai_thu - bill.mien_giam}`)) : '0',
        ma_sv: bill.ma_sv,
        trang_thai:
          bill.trang_thai === 0
            ? STATUS.chua_thu
            : bill.trang_thai === 1
            ? STATUS.da_thu
            : bill.trang_thai === 3
            ? STATUS.thu_mot_phan
            : `${STATUS.dang_thanh_toan}-${bill?.time_pending}`,
        expaned: '0',
        hocKy: bill?.hoc_ky_chu,
        loaiThu: bill?.ma_loai_thu?.typeFeeName,
        date_line: bill.date_line ? moment(bill.date_line).format('HH:mm:ss DD/MM/YYYY') : '-',
        tongThu: formatMoney(parseFloat(`${bill.tong_thu}`)),
        kenh_thu: bill.kenh_thu,
        // ngay_tao: moment(bill.ngay_tao).format('HH:mm:ss DD/MM/YYYY'),
        ngay_tao: bill?.ngay_tao ? moment(bill.ngay_tao).format('HH:mm:ss DD/MM/YYYY') : '-',
        ngay_thu: bill.ngay_thu ? moment(bill.ngay_thu).format('HH:mm:ss DD/MM/YYYY') : '-',
        hoc_ky_chu: bill.hoc_ky_chu,
        chi_tiet: bill?.chi_tiet,
        createdAt: bill.createdAt,
        so_phieu_bao_group: bill?.so_phieu_bao_group,
        children: bill?.chi_tiet
          ? bill?.chi_tiet.split(',').map((e, index) => ({
              key: index,
              noidung: e,
              name: bill.so_phieu_bao,
              status: bill.trang_thai,
              ma_sv: bill.ma_sv,
              phaiThu: '',
              daThu: '',
              mienGiam: '',
              conNo: '',
              expaned: '0',
              trang_thai: '',
              tongThu: '',
              hocKy: '',
              loaiThu: '',
              date_line: '',
              kenh_thu: '',
              chi_tiet: '',
            }))
          : [],
      }
    })

    item && chi_tiet.push(item)
    setBillRender(chi_tiet)
  }

  useEffect(() => {
    if (bills) {
      handleConvertData()
    }
    setListSelect([])
    setCurrentValue([])
    // console.log(bills)
  }, [bills])

  const handleSetValue = (value: string, so_phieu_bao_group: string) => {
    let idArray: string[] = [...currentValue]

    if (idArray.includes(value)) {
      if (so_phieu_bao_group) {
        so_phieu_bao_group
          .trim()
          .split(' ')
          .forEach((e) => (idArray = idArray.filter((existingId) => existingId !== e)))
      } else {
        idArray = idArray.filter((existingId) => existingId !== value)
      }
    } else {
      if (so_phieu_bao_group) {
        if (!idArray.includes(value)) {
          idArray = []
        }
        so_phieu_bao_group
          .trim()
          .split(' ')
          .forEach((e) => idArray.push(e))
        // console.log(idArray)
      } else {
        idArray.push(value)
      }
    }

    setListSelect(idArray)
    setCurrentValue(idArray)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Số phiếu thu',
      dataIndex: 'name',
      key: 'name',
      render: (value, _) => (
        <div
          style={{ height: _.height }}
          onClick={() => {
            setBills(_)
            setIsModalOpen(true)
          }}
          className={`h-[${_.height}] flex items-center cursor-pointer text-[#045993] font-[600]`}
        >
          {value}
        </div>
      ),
    },
    {
      title: 'Nội dung',
      dataIndex: 'noidung',
      key: 'noidung',
      align: 'left',
      render: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
    },
    {
      title: 'Học kỳ',
      dataIndex: 'hoc_ky_chu',
      key: 'hoc_ky_chu',
      render: (value) => <div className="text-right">{value}</div>,
      align: 'right',
    },
    {
      title: 'Tổng thu (VND)',
      dataIndex: 'tongThu',
      key: 'tongThu',
      render: (value) => <div className="text-right">{value}</div>,
      align: 'right',
    },
    {
      title: 'Miễn giảm (VND)',
      dataIndex: 'mienGiam',
      key: 'mienGiam',
      render: (value) => <div className="text-right">{value}</div>,
      align: 'right',
    },
    {
      title: 'Phải thu (VND)',
      dataIndex: 'phaiThu',
      key: 'phaiThu',
      render: (value) => <div className="text-right">{value}</div>,
      align: 'right',
    },
    {
      title: 'Loại thu',
      dataIndex: 'loaiThu',
      key: 'loaiThu',
      render: (value) => <div className="text-right">{value}</div>,
      align: 'right',
    },
    {
      title: `${status === 1 ? 'Ngày thanh toán' : 'Ngày đến hạn'}`,
      dataIndex: `${status === 1 ? 'ngay_thu' : 'date_line'}`,
      key: `${status === 1 ? 'ngay_thu' : 'date_line'}`,
      render: (value) => <div className="text-right">{value}</div>,
      align: 'right',
    },

    {
      title: 'Trạng thái',
      dataIndex: 'trang_thai',
      key: 'trang_thai',
      render: (value) => <CellRenderStatus value={value} />,
      align: 'right',
    },
  ]

  const renderChecked = (item) => {
    return (
      <input
        type="checkbox"
        checked={listSelect.find((e) => e === item.key) ? true : false}
        className=" checked:bg-[#045993] w-4 h-4 border"
        onChange={() => {
          // console.log(item.key)
          handleSetValue(`${item.key}`, item?.so_phieu_bao_group ?? '')
        }}
        onClick={() => {
          setimgBidv(item.qrCode)
          handleSetValue(`${item.key}`, item?.so_phieu_bao_group ?? '')
        }}
      />
    )
  }

  const renderIconStatus = (item) => {
    if (item.trang_thai === STATUS.chua_thu || item.trang_thai.includes('-')) {
      return renderChecked(item)
    } else if (item.trang_thai === STATUS.da_thu) {
      return <IconCheck />
    } else if (item.trang_thai === STATUS.thu_mot_phan) {
      return <IconThuMotPhan />
    } else {
      return <></>
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (bills.length) {
        setIsLoadingLocal(!bills.length)
      } else {
        setIsLoadingLocal(false)
      }
    }, 800)
    return () => {
      clearTimeout(timer)
    }
  }, [loading1, billRender, bills])

  return (
    <div className="flex flex-col md:mt-12 mt-2">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 md:px-10">
        {isMobile ? (
          <>
            {billRender.length ? (
              billRender
                ?.filter((e) => !!e._id)
                ?.map((item) => {
                  return (
                    <div key={item._id} className="border-solid border-2 border-[#F4F7FC]">
                      <Row gutter={[5, 5]} className="bg-[#F4F7FC] p-1.5">
                        <Col span={22}>
                          <p className="text-[16px] text-[#06163A]">Số phiếu thu</p>
                          <div
                            onClick={() => {
                              setBills(item)
                              setIsModalOpen(true)
                            }}
                            className="flex items-center cursor-pointer text-[#045993] font-[600]"
                          >
                            {item.name}
                          </div>
                        </Col>
                        <Col
                          span={2}
                          className="flex items-center w-full"
                          onClick={() => {
                            item.trang_thai === STATUS.chua_thu ??
                              handleSetValue(`${item.key}`, item?.so_phieu_bao_group ?? '')
                          }}
                        >
                          {renderIconStatus(item)}
                        </Col>
                      </Row>
                      <div className="p-5">
                        <div className="flex justify-between">
                          <span className="text-[#595959]">Phải thu (VND): </span>
                          <span>{item.phaiThu}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-[#595959]"> Nội dung: </span>
                          <div dangerouslySetInnerHTML={{ __html: item.noidung }} />
                        </div>
                        <div className="flex justify-between">
                          <span className="text-[#595959]">Học kỳ: </span>
                          <span>{item.hoc_ky_chu}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-[#595959]">Loại thu: </span>
                          <span>{item.loaiThu}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-[#595959]">Trạng thái: </span>
                          {/* <span>{item.trang_thai}</span> */}
                          <CellRenderStatus value={item.trang_thai} />
                        </div>
                      </div>
                    </div>
                  )
                })
            ) : (
              <div className="mt-8">
                {!isLoadingLocal ? (
                  <>
                    <Empty />
                    <p className="text-center"></p>
                  </>
                ) : (
                  <Spin className="flex justify-center" />
                )}
              </div>
            )}
          </>
        ) : (
          <Table
            loading={!bills.length && isLoadingLocal}
            columns={columns}
            dataSource={billRender}
            pagination={false}
            scroll={{ x: '1650px' }}
            rowSelection={{
              // ...rowSelection,
              renderCell: (_, record) => renderIconStatus(record),
            }}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) => (
                <CaretRightOutlined
                  onClick={(e) => onExpand(record, e)}
                  rotate={expanded ? 90 : 0}
                  className={`${record.expaned === '1' ? '' : 'hidden'}`}
                />
              ),
            }}
          />
        )}
      </div>
      {/* {renderItemReposive()} */}
      <ModalDetail isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} bill={bill} />
    </div>
  )
}

export default memo(TableCustom)
