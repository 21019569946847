import React from 'react'

const Icon = () => {
  return (
    <>
      <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_15_199)">
          <path
            d="M75.7691 10.6624C74.6217 9.51295 72.7599 9.51096 71.6124 10.6575L36.3459 45.8309L23.6219 32.0114C22.5224 30.8179 20.6635 30.7405 19.4681 31.8398C18.2735 32.9393 18.1971 34.7991 19.2965 35.9936L34.0931 52.063C34.6349 52.652 35.3933 52.994 36.193 53.0106C36.2144 53.0115 36.2352 53.0115 36.2557 53.0115C37.0328 53.0115 37.7804 52.7029 38.3311 52.1541L75.7633 14.82C76.9137 13.6736 76.9157 11.8118 75.7691 10.6624Z"
            fill="#1BA803"
          />
          <path
            d="M75.0602 36.0602C73.4365 36.0602 72.1206 37.3762 72.1206 39C72.1206 57.2634 57.2634 72.1206 39 72.1206C20.7376 72.1206 5.8794 57.2634 5.8794 39C5.8794 20.7376 20.7376 5.8794 39 5.8794C40.6237 5.8794 41.9398 4.56346 41.9398 2.93978C41.9398 1.31595 40.6237 0 39 0C17.4952 0 0 17.4952 0 39C0 60.5039 17.4952 78 39 78C60.5039 78 78 60.5039 78 39C78 37.3763 76.6841 36.0602 75.0602 36.0602Z"
            fill="#1BA803"
          />
        </g>
        <defs>
          <clipPath id="clip0_15_199">
            <rect width="78" height="78" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default Icon
