import Button from '@components/Button'
import { LIST_VISA } from '@utils/constan'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Type_Initstate } from 'store/appSlice'
import { BillType, IPaymentMethod, TypeMethodPayment } from '../../../types/general'
import BillingInformation from './BillingInformation'
import MethodPayment from './MethodPayment'
import ModalACB from './Modal/ModalACB'
import ModalBIDV from './Modal/ModalBIDV'
import ModalSHB from './Modal/ModalSHB'

interface P {
  napas
  toastMsgPTTT: boolean
  appState: Type_Initstate
  child: TypeMethodPayment
  listMethod: TypeMethodPayment[]
  itemPaymentMethod: TypeMethodPayment
  bills: BillType[]
  payment: string
  toastMsgHTTT: boolean
  tien_toi_thieu: number
  visiable: boolean
  imgBidv: string
  imgShb: string
  visiableShb: boolean
  imgACB: string
  visiableACB: boolean
  paymentMethod: IPaymentMethod[]

  handleCreateBill: () => Promise<boolean>
  setPayment: React.Dispatch<React.SetStateAction<string>>
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  setVisibleShb: React.Dispatch<React.SetStateAction<boolean>>
  setVisibleACB: React.Dispatch<React.SetStateAction<boolean>>
  set_tien_toi_thieu: React.Dispatch<React.SetStateAction<number>>
  setChild: React.Dispatch<React.SetStateAction<TypeMethodPayment>>
  setItemPaymentMethod: React.Dispatch<React.SetStateAction<TypeMethodPayment>>
}

const View = ({
  napas,
  bills,
  child,
  imgShb,
  imgACB,
  payment,
  imgBidv,
  appState,
  visiable,
  listMethod,
  visiableACB,
  visiableShb,
  toastMsgHTTT,
  toastMsgPTTT,
  paymentMethod,
  tien_toi_thieu,
  itemPaymentMethod,

  setChild,
  setPayment,
  setVisibleACB,
  setVisible,
  setVisibleShb,
  handleCreateBill,
  set_tien_toi_thieu,
  setItemPaymentMethod,
}: P) => {
  const [visa] = useState(LIST_VISA)
  const navigate = useNavigate()

  return (
    <>
      <div className="flex w-full lg:mt-10 xl:flex-row flex-col px-3">
        <div className="xl:pr-8 mt-6 xl:my-0">
          <div className="mb-6">
            {appState.device === 'web' && appState.studenInfor && (
              <div className="md:hidden mb-3">
                <p>
                  Tên học viên:{' '}
                  <span className="text-[#151515] text-[16px] font-[500]">{appState.studenInfor?.HoTen}</span>
                </p>
                <p>
                  Mã học viên:{' '}
                  <span className="text-[#151515] text-[16px] font-[500]">{appState.studenInfor?.MaSV}</span>
                </p>
              </div>
            )}
            {paymentMethod.length !== 0 && (
              <>
                <p className="text-[#151515] font-[500] text-[18px] mb-1">Chọn phương thức thanh toán</p>
                {listMethod.map((e) => (
                  <MethodPayment
                    item={e}
                    key={e.id}
                    child={child}
                    setChild={setChild}
                    toastMsg={toastMsgPTTT}
                    itemPaymentMethod={itemPaymentMethod}
                    setItemPaymentMethod={setItemPaymentMethod}
                  />
                ))}
              </>
            )}
          </div>
        </div>

        <div className="w-full">
          <div className="">
            <BillingInformation
              visa={visa}
              napas={napas}
              bills={bills}
              child={child}
              payment={payment}
              setPayment={setPayment}
              toastMsg={toastMsgHTTT}
              tien_toi_thieu={tien_toi_thieu}
              itemPaymentMethod={itemPaymentMethod}
              set_tien_toi_thieu={set_tien_toi_thieu}
            />
          </div>

          <div className={`mt-10 flex justify-end lg:pb-10 lg:mt-4 ${appState.device !== 'web' && 'my-6'}`}>
            <button
              onClick={() => navigate(-1)}
              className={`rounded-[10px] shadow-[#00000040] ${'bg-[#B7B7B7]'}  p-2 px-6 text-[16px] font-[500] shadow-lg  ${'text-white'} lg:h-[51px] h-[43px] ${'hover:bg-[#8e8e8e]'} focus:outline-none focus:shadow-outline transition duration-500 ease-in-out w-[170px] mr-4`}
            >
              Quay lại
            </button>
            <Button
              text="Thanh toán"
              disabled={paymentMethod.length === 0 || (itemPaymentMethod && itemPaymentMethod.child.length === 0)}
              onClick={() => {
                handleCreateBill()
              }}
            />
          </div>
        </div>
      </div>
      <ModalBIDV setIsModalOpen={setVisible} isModalOpen={visiable} img={imgBidv} />
      <ModalSHB setIsModalOpen={setVisibleShb} isModalOpen={visiableShb} img={imgShb} />
      <ModalACB setIsModalOpen={setVisibleACB} isModalOpen={visiableACB} img={imgACB} />
    </>
  )
}

export default View
