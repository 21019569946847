import ConfirmPayment from '@pages/ConfirmPayment'
import ErrorPage from '@pages/Error'
import Home from '@pages/Home'
import PageNotFound from '@pages/PageNotFound'
import Payment from '@pages/Payment'
import PaymentResult from '@pages/PaymentResult'
import TransactionHistory from '@pages/TransactionHistory'
import React from 'react'
import { useRoutes } from 'react-router-dom'

const Router = () => {
  const routes = useRoutes([
    { path: '', element: <Home /> },
    { path: '/search/:orgCode/school/:schoolCode', element: <TransactionHistory /> },
    {
      path: '/organization/:orgCode/school/:schoolCode/student/:studentID',
      element: (
        <React.Suspense fallback={<>...</>}>
          <Payment />
        </React.Suspense>
      ),
    },
    {
      path: '/confirm-payment/organization/:orgCode/school/:schoolCode/student/:studentID',
      element: (
        <React.Suspense fallback={<>...</>}>
          <ConfirmPayment />
        </React.Suspense>
      ),
    },

    {
      path: '/payment-result',
      element: (
        <React.Suspense fallback={<>...</>}>
          <PaymentResult />
        </React.Suspense>
      ),
    },

    {
      path: '/payment-result/onepay/organization/:orgCode/school/:schoolCode/student/:studentID',
      element: (
        <React.Suspense fallback={<>...</>}>
          <PaymentResult />
        </React.Suspense>
      ),
    },

    {
      path: '/error',
      element: (
        <React.Suspense fallback={<>...</>}>
          <ErrorPage />
        </React.Suspense>
      ),
    },

    // ======================= UAT  =======================

    { path: '/uat', element: <Home /> },
    { path: '/uat/search/:orgCode/school/:schoolCode', element: <TransactionHistory /> },
    {
      path: '/uat/organization/:orgCode/school/:schoolCode/student/:studentID',
      element: (
        <React.Suspense fallback={<>...</>}>
          <Payment />
        </React.Suspense>
      ),
    },
    {
      path: '/uat/confirm-payment/organization/:orgCode/school/:schoolCode/student/:studentID',
      element: (
        <React.Suspense fallback={<>...</>}>
          <ConfirmPayment />
        </React.Suspense>
      ),
    },

    {
      path: '/uat/payment-result',
      element: (
        <React.Suspense fallback={<>...</>}>
          <PaymentResult />
        </React.Suspense>
      ),
    },

    {
      path: '/uat/payment-result/onepay/organization/:orgCode/school/:schoolCode/student/:studentID',
      element: (
        <React.Suspense fallback={<>...</>}>
          <PaymentResult />
        </React.Suspense>
      ),
    },

    {
      path: '/uat/error',
      element: (
        <React.Suspense fallback={<>...</>}>
          <ErrorPage />
        </React.Suspense>
      ),
    },

    {
      path: '*',
      element: (
        <React.Suspense fallback={<>...</>}>
          <PageNotFound />
        </React.Suspense>
      ),
    },
  ])

  return routes
}

export default Router
