import { InputNumber as InputNumberAntd } from 'antd'
import React, { useEffect, useState } from 'react'

interface P {
  onChange?: (value: number) => void
  setShowErr?: React.Dispatch<React.SetStateAction<boolean>>
  defaultValue: number
  min: number
  max: number
}

const InputNumber = ({ onChange, defaultValue, max, min, setShowErr }: P) => {
  const [defaultNumber, setDefault] = useState(1)

  useEffect(() => {
    setDefault(defaultValue)
  }, [defaultValue])

  return (
    <InputNumberAntd
      min={0}
      defaultValue={defaultNumber}
      onChange={(e) => {
        onChange && onChange(e)
        setDefault(e)

        if (e < min) {
          setShowErr(true)
        } else if (e > max) {
          setShowErr(true)
        } else {
          setShowErr(false)
        }
      }}
      className="rounded-[10px] px-4 py-2 focus:outline-none focus:border-blue-600 border-[#9f9f9f] border h-[43px] md:h-[51px] w-full placeholder-gray-400 text-right"
      formatter={(e) => `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      value={defaultNumber}
      step={1000}
      controls={false}
      keyboard={true}
    />
  )
}

export default InputNumber
