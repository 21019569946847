import useWindowDimensions from '@hooks/useWindowDimensions'
import { useDebounce } from '@utils/debounce'
import { Col, Form, Input, Radio, Select } from 'antd'
import React, { FC, useEffect, useMemo, useState } from 'react'

const { Option } = Select

interface IProps {
  bill
  searchedColumn
  setSearchedColumn: React.Dispatch<any>
  setStatus: React.Dispatch<React.SetStateAction<number>>
}

const SectionHeaderTable: FC<IProps> = ({ bill, searchedColumn, setSearchedColumn, setStatus }: IProps) => {
  const [form] = Form.useForm()
  const [loaiThu, setLoaiThu] = useState<{ name: string; value: string }[]>([])
  const [nienHoc, setNienHoc] = useState<{ name: string; value: string }[]>([])
  const [cloneBill, setCloneBill] = useState([])
  const { width } = useWindowDimensions()

  const isMobile = useMemo(() => width < 640, [width]) // is mobile size if width < 640

  useEffect(() => {
    setLoaiThu(
      cloneBill
        .filter((e) => e.ma_loai_thu?.typeFeeName.trim() || e?.ma_loai_thu?.typeFeeCode.trim())
        .map((e) => ({ name: e?.ma_loai_thu?.typeFeeName, value: e?.ma_loai_thu?.typeFeeCode }))
        .reduce((accumulator, current) => {
          if (!accumulator.some((item) => item.name === current.name && item.value === current.value)) {
            accumulator.push(current)
          }
          return accumulator
        }, []),
    )
    setNienHoc(
      cloneBill
        .filter((e) => e.hoc_ky_chu.trim())
        .map((e) => ({ name: e.hoc_ky_chu, value: e.hoc_ky_chu }))
        .reduce((accumulator, current) => {
          if (!accumulator.some((item) => item.name === current.name && item.value === current.value)) {
            accumulator.push(current)
          }
          return accumulator
        }, []),
    )
  }, [cloneBill])

  const handleQuery = useDebounce(({ value, key, type, filterType }) => {
    const obj = {}
    // console.log
    value = value === 'ALL' ? '' : value === 4 ? '' : value
    obj[key] = {
      filter: value,
      filterType,
      type,
    }
    const data = { ...searchedColumn, ...obj }
    const searched = {}
    Object.keys(data).forEach((e) => {
      if (data[e].filter || data[e].filter === 0) {
        searched[`${e}`] = data[e]
      }
    })
    setSearchedColumn(searched)
    if (key === 'trang_thai') {
      setStatus(value)
    }
  }, 1000)

  useEffect(() => {
    setCloneBill(bill)
  }, [bill])

  return (
    <Form layout={'inline'} form={form} className="mt-4">
      {isMobile ? (
        <Col span={16}>
          <Form.Item label="Loại thu" name="loai_thu" className="text-[#595959] text-[16px] font-[400]">
            <Select
              className="w-full custom-input sm:mb-2"
              onChange={(e) =>
                handleQuery({ value: e, key: 'ma_loai_thu.typeFeeCode', type: 'eq', filterType: 'text' })
              }
              defaultValue={'ALL'}
            >
              <Option value="ALL">Tất cả</Option>
              {loaiThu.map((e) => (
                <Option value={e.value} key={e.value}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        <Form.Item label="Loại thu" name="loai_thu" className="text-[#595959] text-[16px] font-[400]">
          <Select
            className="w-full custom-input sm:mb-2"
            onChange={(e) => handleQuery({ value: e, key: 'ma_loai_thu.typeFeeCode', type: 'eq', filterType: 'text' })}
            defaultValue={'ALL'}
          >
            <Option value="ALL">Tất cả</Option>
            {loaiThu.map((e) => (
              <Option value={e.value} key={e.value}>
                {e.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {isMobile ? (
        <Col span={16}>
          <Form.Item label="Học kỳ" name="hoc_ky_chu">
            <Select
              className="w-full custom-input sm:mb-2"
              onChange={(e) => handleQuery({ value: e, key: 'hoc_ky_chu', type: 'eq', filterType: 'text' })}
              defaultValue={'ALL'}
            >
              <Option value="ALL">Tất cả</Option>
              {nienHoc.map((e) => (
                <Option value={e.value} key={e.value}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        <Form.Item label="Học kỳ" name="hoc_ky_chu">
          <Select
            className="w-full custom-input sm:mb-2"
            onChange={(e) => handleQuery({ value: e, key: 'hoc_ky_chu', type: 'eq', filterType: 'text' })}
            defaultValue={'ALL'}
          >
            <Option value="ALL">Tất cả</Option>
            {nienHoc.map((e) => (
              <Option value={e.value} key={e.value}>
                {e.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {!isMobile && (
        <Form.Item label="Số phiếu thu" name="so_phieu_bao" className="so_phieu_bao ">
          <Input
            placeholder="Tìm kiếm"
            className="custom-input sm:mb-2  text-[#595959] font-[400] width-custom"
            onChange={(e) =>
              handleQuery({ value: e.target.value, key: 'so_phieu_bao', type: 'contains', filterType: 'text' })
            }
          />
        </Form.Item>
      )}

      {isMobile ? (
        <Col span={16}>
          <Form.Item label="Trạng thái" name="trang_thai" className="text-[#595959] text-[16px] font-[400]">
            <Select
              className="w-full custom-input sm:mb-2"
              defaultValue={0}
              onChange={(e) => {
                handleQuery({ value: e, key: 'trang_thai', type: 'eq', filterType: 'text' })
              }}
              options={[
                { value: 4, label: 'Tất cả' },
                { value: 1, label: 'Đã đóng' },
                { value: 0, label: 'Chưa đóng' },
                { value: 2, label: 'Đang thanh toán' },
                { value: 3, label: 'Đóng một phần' },
              ]}
            />
          </Form.Item>
        </Col>
      ) : (
        <Form.Item name="trang_thai" className="mt-[4px]">
          <Radio.Group
            name="radiogroup"
            defaultValue={0}
            onChange={(e) => {
              handleQuery({ value: e.target.value, key: 'trang_thai', type: 'eq', filterType: 'text' })
            }}
            className="items-center flex flex-wrap w-[300px] sm:w-full"
          >
            <Radio value={4}>Tất cả</Radio>
            <Radio value={1}>Đã đóng</Radio>
            <Radio value={0}>Chưa đóng</Radio>
            <Radio value={2}>Đang thanh toán</Radio>
            <Radio value={3}>Đóng một phần</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {isMobile && (
        <Col span={16}>
          <Form.Item label="Số phiếu thu" name="so_phieu_bao">
            <Input
              placeholder="Tìm kiếm"
              className="custom-input sm:mb-2  text-[#595959] font-[400] width-custom"
              onChange={(e) =>
                handleQuery({ value: e.target.value, key: 'so_phieu_bao', type: 'contains', filterType: 'text' })
              }
            />
          </Form.Item>
        </Col>
      )}
    </Form>
  )
}

export default SectionHeaderTable
