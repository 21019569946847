import React, { FC } from 'react'

interface IProps {
  placeholder?: string
  onChange?: (value: string) => void
  value?: string
  defaultValue?: string
}

const Input: FC<IProps> = ({ placeholder = 'Nhập nội dung...', onChange, defaultValue, value }: IProps) => {
  return (
    <>
      <input
        type="text"
        className="rounded-[10px] px-4 py-2 focus:outline-none focus:border-blue-600 border-[#9f9f9f] border h-[43px] md:h-[51px] w-full placeholder-gray-400"
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => {
          onChange && onChange(e.target.value)
        }}
      />
    </>
  )
}

export default Input
