import React from 'react'
import './style.css'

const Loading = () => {
  return (
    <div className="wrapper-c">
      <button>
        Loading ...
        <svg>
          <rect x="1" y="1"></rect>
        </svg>
      </button>
    </div>
  )
}

export default Loading
