import DesktopFrame from '@components/templates/DesktopFrame'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { message } from 'antd'
import 'antd/dist/reset.css'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import './index.css'
import Routes from './routes'
import { store } from './store'

import * as Sentry from '@sentry/react'

function App() {
  const CLIENT_ID = process.env.REACT_APP_CLINET_ID

  message.config({
    duration: 3,
  })

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Provider store={store}>
        <Router>
          <DesktopFrame>
            <Routes />
          </DesktopFrame>
        </Router>
      </Provider>
    </GoogleOAuthProvider>
  )
}

export default Sentry.withProfiler(App)
