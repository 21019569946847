// import ModalCaptcha from '@pages/Home/components/ModalCaptcha'
import Search from '@pages/Payment/Components/Search'
import * as Sentry from '@sentry/browser'
import { NetWorkService, validResponse } from '@utils/API'
import { CODE_SUCCESS } from '@utils/constan'
import { detectEnvUat } from '@utils/general'
import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'store'
import { setEduBillDetail, setLoading, setStudenInfor, Type_Initstate } from 'store/appSlice'
import { ItemBillResponse } from '../../types/general'
import Icon from './Components/Icon'
import TableHistory from './Components/TableHistory'

const dataIntroduce = [
  {
    title: 'Thuận tiện',
    img: <Icon />,
    desc: 'Cổng thanh toán trực tuyến tạo sự thuận tiện cho người học trong quá trình thanh toán học phí và các khoản phải nộp khác thông qua hệ thống thanh toán trực tuyến',
  },
  {
    title: 'An toàn',
    img: <Icon />,
    desc: 'Có thể sử dụng thẻ ngân hàng của nhiều ngân hàng khác nhau và kiểm tra được tình trạng học phí',
  },
  {
    title: 'Nhanh chóng',
    img: <Icon />,
    desc: 'Thanh toán học phí nhanh chóng, không phải xếp hàng chờ, không giới hạn không gian và thời gian',
  },
  {
    title: 'Tiện lợi',
    img: <Icon />,
    desc: 'Phụ huynh, người thân có thể nộp học phí thay cho người học một cách dễ dàng, tiện lợi',
  },
]

const TransactionHistory = () => {
  const { Get } = NetWorkService
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [isFalse, setIsFalse] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const { listEdubillDetail, edubillDetail } = useSelector<RootState, Type_Initstate>((state) => state.app)

  const handleGetData = async (maSv: string, cccd: string) => {
    try {
      dispatch(setLoading(true))
      const res: any = await Get<ItemBillResponse>({
        url: `/api/organization/${params.orgCode}/school/${params.schoolCode}/secret/${maSv}/${
          edubillDetail?.isSearchCCCD ? cccd : 0
        }`,
      })
      if (res && validResponse(res) && res.code === CODE_SUCCESS && res?.data.data.success !== false) {
        const response = res?.data.data
        navigate(
          `${detectEnvUat() ? '/uat' : ''}/organization/${params.orgCode}/school/${params.schoolCode}/student/${
            response.secretCode
          }`,
          {
            state: { maSv, cccd },
          },
        )
        setIsFalse(false)
      } else {
        setIsFalse(true)
        res && validResponse(res) ? message.error(res.data.data.message) : message.error('Thông tin tra cứu không đúng')
        Sentry.captureMessage('Thông tin tra cứu không đúng')
      }
    } catch (error) {
      setIsFalse(true)
      message.error('Có lỗi xảy ra')
      console.log(error)
      Sentry.captureException(error)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const handleNavigate = async (maSv: string, cccd: string) => {
    setValueSearch(maSv)
    handleGetData(maSv, cccd)
  }

  useEffect(() => {
    dispatch(setStudenInfor(undefined))
  }, [])

  useEffect(() => {
    if (listEdubillDetail.length !== 0 && !edubillDetail) {
      dispatch(setEduBillDetail(listEdubillDetail.find((e) => e.orgCode === params.orgCode)))
    }
  }, [listEdubillDetail, params.orgCode])

  const renderIntroduce = () => {
    return (
      <div className="mx-auto px-4 md:mt-[10%]">
        <div className="text-[#045993] md:text-[30px] md:font-[700] text-center sm:text-[20px] text-[18px] font-[600] mt-[33%] md:mt-0">
          HỆ THỐNG THANH TOÁN TRỰC TUYẾN -{' '}
          <span
            className=""
            dangerouslySetInnerHTML={{
              __html: edubillDetail?.schoolName,
            }}
          />
        </div>
        <div className="mt-4 text-center lg:text-[20px] font-[400]">
          Cổng thanh toán trực tuyến EDUBILLS GATEWAY được sử dụng đề người học thanh toán học phí và các khoản thu khác
          tại{' '}
          <span
            className=""
            dangerouslySetInnerHTML={{
              __html: edubillDetail?.schoolName,
            }}
          />
        </div>

        <div className="flex md:flex-row flex-col mx-auto max-w-[1200px] mt-2 sm:mt-10 md:h-[416px] md:px-0 px-4">
          {dataIntroduce.map((e, i) => (
            <div
              className="rounded-[16px] bg-white lg:pt-6 lg:px-5 pt-4 px-3 flex flex-col boxShadow-1 md:mx-3 items-center md:max-w-[290px] md:mt-0 mt-5 w-full h-[350px] sm:h-[400px]"
              key={i}
            >
              <div className="text-[#06163A] text-[22px] pb-2 sm:text-[30px] font-[600] sm:pb-4 border-b-2 border-[#045993]">
                {e.title}
              </div>
              <div className="pt-5">{e.img}</div>
              <div
                className="pt-5 text-[16px] font-[300] text-center"
                style={{
                  color: 'rgba(83, 97, 108, 0.85)',
                }}
              >
                {e.desc}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  // bills.length === 0 && valueSearch
  return (
    <div className="relative">
      <Search handleGetBillList={handleNavigate} isHistory={true} valueSearch1={valueSearch} />

      {!valueSearch && !isFalse && renderIntroduce()}
      <TableHistory isFalse={isFalse} valueSearch={valueSearch} />
    </div>
  )
}

export default TransactionHistory
