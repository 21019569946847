import InputNumber from '@components/InputNumber'
import useWindowDimensions from '@hooks/useWindowDimensions'
import { PaymentMethodKey } from '@utils/constan'
import { formatMoney } from '@utils/general'
import { Col, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { BillType, TypeMethodPayment } from '../../../types/general'
import Payments from './Payments'

export type IBANK = {
  id: number
  name: string
  code: string
  bin: string
  shortName: string
  logo: string
  transferSupported: number
  lookupSupported: number
  short_name: string
  support: number
  isTransfer: number
  swift_code: string
}

interface P {
  visa: IBANK[]
  napas: IBANK[]
  payment: string
  child: TypeMethodPayment
  bills: BillType[]
  toastMsg: boolean
  tien_toi_thieu: number
  itemPaymentMethod: TypeMethodPayment | undefined
  setPayment: React.Dispatch<React.SetStateAction<string>>
  set_tien_toi_thieu: React.Dispatch<React.SetStateAction<number>>
}

const BillingInformation = ({
  visa,
  bills,
  napas,
  child,
  payment,
  toastMsg,
  setPayment,
  tien_toi_thieu,
  set_tien_toi_thieu,
  itemPaymentMethod,
}: P) => {
  const { width } = useWindowDimensions()
  const isMobile = useMemo(() => width < 640, [width]) // is mobile size if width < 640
  const [showErr, setShowErr] = useState(false)
  const [showErr2, setShowErr2] = useState(false)
  const [tien_toi_thieu1, setTien_toi_thieu1] = useState(0)
  const [tien_toi_da, setTien_toi_da] = useState(0)

  useEffect(() => {
    const defaultValue = 0
    let minAmount = bills.find((e) => e.tien_toi_thieu)
      ? bills.find((e) => e.tien_toi_thieu)?.tien_toi_thieu
      : defaultValue

    if (bills.some((e) => e.is_bat_buoc_thanh_toan_het)) {
      minAmount = bills.reduce((a, b) => {
        if (b.is_bat_buoc_thanh_toan_het) {
          a += b.phai_thu
        }
        return a
      }, 0)
    }

    const tien_toi_da = bills?.reduce((a, b) => a + +b.phai_thu, 0)

    if (tien_toi_da < minAmount) {
      minAmount = tien_toi_da
    }

    bills.every((e) => e?.is_nhap_tien) && !tien_toi_thieu && set_tien_toi_thieu(tien_toi_da)

    setTien_toi_thieu1(minAmount)
    setTien_toi_da(tien_toi_da)
  }, [bills])

  useEffect(() => {
    if (tien_toi_thieu % 1000 && tien_toi_thieu < tien_toi_da && tien_toi_thieu1 < tien_toi_thieu) {
      setShowErr2(true)
    } else {
      setShowErr2(false)
    }
  }, [tien_toi_thieu])

  const renderInput = () => {
    return (
      <>
        <InputNumber
          max={tien_toi_da}
          min={tien_toi_thieu1}
          defaultValue={tien_toi_da}
          onChange={set_tien_toi_thieu}
          setShowErr={setShowErr}
        />
      </>
    )
  }

  return (
    <>
      {child &&
        (child.id === PaymentMethodKey.VISA || child.id === PaymentMethodKey.ATM) &&
        payment !== PaymentMethodKey.ONEPAY && (
          <>
            <p className="text-[#151515] font-[500] text-[18px] text-left">Chọn hình thức thanh toán</p>
            <Payments
              visa={visa}
              child={child}
              napas={napas}
              payment={payment}
              toastMsg={toastMsg}
              setPayment={setPayment}
              itemPaymentMethod={itemPaymentMethod}
            />
          </>
        )}
      <p className="text-[#151515] font-[500] text-[18px] text-left mt-6 xl:mt-0">Thông tin thanh toán</p>
      {isMobile ? (
        <>
          {bills?.map((item, index) => {
            return (
              <div
                key={item._id}
                className={`border-solid border-2 border-[#ededed]  ${index === 0 && 'rounded-t-[10px]'}`}
              >
                <Row gutter={[5, 5]} className={`bg-[#ededed] px-5 py-2 ${index === 0 && 'rounded-t-[10px]'}`}>
                  <Col span={22}>
                    <p className="text-[16px] font-bold">STT</p>
                  </Col>
                  <Col span={2} className="text-right text-[16px] font-bold">
                    {index + 1}
                  </Col>
                </Row>
                <div className="p-5">
                  <div className="flex justify-between">
                    <span className="text-[#595959]">Số phiếu thu: </span>
                    <span>{item.so_phieu_bao}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[#595959]"> Nội dung: </span>
                    <div dangerouslySetInnerHTML={{ __html: item.noi_dung }} />
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[#595959]">Số tiền (VND): </span>
                    <span>{formatMoney(parseFloat(`${item?.phai_thu}`))}</span>
                  </div>
                </div>
              </div>
            )
          })}
          <div className="flex justify-between px-5 py-3 border-solid border-[#ededed] border-[2px] items-center rounded-b-[10px]">
            <span className="text-[16px] font-bold w-[115px]">Tổng (VND)</span>
            <span className="text-[16px] font-bold">
              {bills.every((e) => e?.is_nhap_tien) ? (
                <>{renderInput()}</>
              ) : (
                <>{formatMoney(bills?.reduce((a, b) => a + +b.phai_thu, 0))} đ</>
              )}
            </span>
          </div>
        </>
      ) : (
        <div className="overflow-x-scroll">
          <div className="border rounded-[16px] border-[#D1D1D1] mt-1 min-w-[670px]">
            <div className="flex justify-between border-b">
              <span className="text-[#151515] font-[500] text-[18px] text-center w-[2%] p-4 ">STT</span>
              <span className="text-[#151515] font-[500] text-[18px] text-left w-[45%] p-4 pl-4"> Nội dung</span>
              <span className="text-[#151515] font-[500] text-[18px] text-left w-[20%] p-4">Số phiếu thu</span>
              <span className="text-[#151515] font-[500] text-[18px] text-right w-[28%] p-4 ">Số tiền</span>
            </div>
            <div className="">
              {bills?.map((e, i) => (
                <div className="flex justify-between border-b" key={e.so_phieu_bao}>
                  <span className="text-[#151515] font-[400] text-[18px] text-center w-[2%] p-4 ml-2">{i + 1}</span>
                  <span
                    className="text-[#151515] font-[400] text-[18px] text-left w-[45%] p-4 pl-4"
                    dangerouslySetInnerHTML={{ __html: e?.noi_dung }}
                  ></span>
                  <span className="text-[#151515] font-[400] text-[18px] text-left w-[20%] p-4">{e?.so_phieu_bao}</span>
                  <span className="text-[#151515] font-[400] text-[18px] text-right w-[28%] p-4">
                    {formatMoney(parseFloat(`${e?.phai_thu}`))} đ
                  </span>
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center">
              <span className="text-[#151515] font-[500] text-[18px] text-center w-[2%] p-4 "></span>
              <span className="text-[#151515] font-[500] text-[18px] text-left w-[45%] p-4 pl-4"></span>
              <span className="text-[#151515] font-[500] text-[18px] text-left w-[20%] p-4">
                Tổng số tiền thanh toán
              </span>
              <span className="text-[#151515] font-[500] text-[18px] text-right w-[28%] p-4">
                {bills.every((e) => e?.is_nhap_tien) ? (
                  <>{renderInput()}</>
                ) : (
                  <>{formatMoney(bills?.reduce((a, b) => a + +b.phai_thu, 0))} đ</>
                )}
              </span>
            </div>
          </div>
        </div>
      )}
      {showErr && (
        <p className="text-[#FD0606BF] font-[13px] text-right mt-4">
          Vui lòng thanh toán tối thiểu {formatMoney(tien_toi_thieu1)} đ và tối đa{' '}
          {formatMoney(bills?.reduce((a, b) => a + +b.phai_thu, 0))} đ
        </p>
      )}
      {showErr2 && <p className="text-[#FD0606BF] font-[13px] text-right mt-4">Thanh toán chẵn đến hàng nghìn</p>}
    </>
  )
}

export default BillingInformation
