import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setHidden } from 'store/appSlice'
import './style.css'

const PageNotFound = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isUat, setIsUat] = useState('NO')

  useEffect(() => {
    dispatch(setHidden(true))
    const uat = localStorage.getItem('uat')
    uat && setIsUat(uat)

    return () => {
      dispatch(setHidden(false))
    }
  }, [])

  return (
    <>
      <section className="page_404">
        <div className="container-1">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">Look like you are lost</h3>

                  <p>the page you are looking for not avaible!</p>

                  <a className="link_404 cursor-pointer" onClick={() => navigate(`${isUat === 'YES' ? '/uat' : '/'}`)}>
                    Go to Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PageNotFound
