import IconCancel from '@pages/Payment/Components/IconCancel'
import { detectEnvUat } from '@utils/general'
import { Modal } from 'antd'
import React, { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface IProps {
  img?: string
  isModalOpen: boolean
  allowCancel?: boolean
  setIsModalOpen: (value: boolean) => void
}

const ModalBIDV: FC<IProps> = ({ isModalOpen, img, allowCancel, setIsModalOpen }: IProps) => {
  const navigate = useNavigate()
  const params = useParams()

  const handleNavigate = () => {
    navigate(
      `${detectEnvUat() ? '/uat' : ''}/organization/${params.orgCode}/school/${params.schoolCode}/student/${
        params.studentID
      }`,
    )
  }

  const handleCancel = () => {
    if (allowCancel) {
      setIsModalOpen(false)
    } else if (window.confirm('Phiếu thu chỉ có thể tạo lại sau 15 phút. Bạn có muốn thoát !')) {
      setIsModalOpen(false)
      handleNavigate()
    }
  }

  useEffect(() => {
    let timeout

    if (isModalOpen) {
      timeout = setTimeout(() => {
        handleNavigate()
      }, 300000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isModalOpen])

  const beforeUnLoad = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.returnValue = 'akdjlasjdkasjdlaksj'
  }

  useEffect(() => {
    if (isModalOpen) {
      window.addEventListener('beforeunload', beforeUnLoad)
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad)
    }
  }, [isModalOpen])

  return (
    <>
      <Modal title={<></>} open={isModalOpen} footer={<></>} className="p-0 custom-modal rouder-31">
        <div className="relative bg-white border-[20px] border-[#016B69] rounded-[30px] pt-4">
          <span className="absolute right-2 top-2">
            <IconCancel
              handleCancel={() => {
                handleCancel()
              }}
            />
          </span>
          <img src={`data:image/png;base64, ${img}`} alt="" className="mx-auto w-full" />
        </div>
      </Modal>
    </>
  )
}

export default ModalBIDV
