import { PaymentMethodKey } from '@utils/constan'
import React, { useEffect, useState } from 'react'

import { TypeMethodPayment } from '../../../types/general'
import { IBANK } from './BillingInformation'
import IconCheck from './IconCheck'

interface P {
  visa: IBANK[]
  napas: IBANK[]
  payment: string
  toastMsg: boolean
  child: TypeMethodPayment
  itemPaymentMethod: TypeMethodPayment
  setPayment: React.Dispatch<React.SetStateAction<string>>
}

const Payments = ({ itemPaymentMethod, payment, setPayment, toastMsg, napas, visa, child }: P) => {
  const [collsap, setCollsap] = useState(true)

  const handleToggleActive = () => {
    setCollsap(!collsap)
  }

  const renderBankCard = (type: 'visa' | 'bank') => {
    const payments = type === 'bank' ? napas : visa

    if (child && child.id === PaymentMethodKey.ONEPAY) {
      return renderQR()
    }

    return (
      <div
        className={`overflow-hidden px-6 duration-1000 max-h-0 group-[.is-active]:max-h-[600px] bg-[#F4F4F4] ${
          collsap ? 'pt-4' : ''
        }pb-0`}
      >
        {itemPaymentMethod.child.length !== 0 && (
          <div className="flex flex-wrap md:max-h-[370px] max-h-[200px] overflow-y-auto pt-3">
            {payments.map((e, i) => (
              <div className="relative " key={i}>
                {payment === e.code && (
                  <div className="absolute right-5 top-1">
                    <IconCheck />
                  </div>
                )}
                <img
                  src={require(`@assets/bank/${e.logo}`)}
                  alt=""
                  className={`mr-4 mb-4 bg-white max-w-[140px] shadow-md rounded-[5px] cursor-pointer ${''} h-[55px]`}
                  onClick={() => setPayment(e.code)}
                  style={{
                    border: `1px solid ${payment === e.code ? '#00BCE8' : '#EFEFEF'}`,
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  const renderQR = () => {
    return (
      <>
        {child && itemPaymentMethod.child.length !== 0 && (
          <div
            className={`overflow-hidden px-6 duration-500 max-h-0 group-[.is-active]:max-h-[200px] bg-[#F4F4F4] ${
              collsap ? 'pt-4 last:pb-4' : ''
            }`}
            key={child?.id}
            onClick={() => setPayment(`${child?.id}`)}
          >
            <div className={`flex items-center h-[66px] rounded-[16px] bg-white border p-3 ${'border-[#C8F5FF]'} `}>
              <div className="">
                <img src={require(`@assets/icon/${child?.icon}.png`)} className="w-[auto] max-w-[40px] h-full" />
              </div>
              <div className="pl-3 w-full">
                <p className="text-[#151515] text-[16px] font-[500]">{child?.title}</p>
              </div>
              <div className="pl-3 flex justify-end items-end w-full">
                <input type="radio" className="form-radio" value={payment} checked={payment === child?.id} />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  const renderWallet = () => {
    return (
      <>
        {itemPaymentMethod && itemPaymentMethod.child.length !== 0 && (
          <div
            className={`overflow-hidden px-6 duration-500 max-h-0 group-[.is-active]:max-h-[200px] bg-[#F4F4F4] ${
              collsap ? 'pt-4 last:pb-4' : ''
            }`}
            key={child?.id}
            onClick={() => setPayment(`${itemPaymentMethod?.id}`)}
          >
            <div className={`flex items-center h-[66px] rounded-[16px] bg-white border p-3 ${'border-[#C8F5FF]'} `}>
              <div className="">
                <img
                  src={require(`@assets/icon/${itemPaymentMethod.payments[0]?.icon}.png`)}
                  className="w-[auto] max-w-[40px] h-full"
                />
              </div>
              <div className="pl-3 w-full">
                <p className="text-[#151515] text-[16px] font-[500]">{itemPaymentMethod.payments[0].title}</p>
              </div>
              <div className="pl-3 flex justify-end items-end w-full">
                <input
                  type="radio"
                  className="form-radio"
                  value={payment}
                  checked={payment === itemPaymentMethod?.id}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    itemPaymentMethod && itemPaymentMethod.id === 'WALLET' && setPayment(`${'VNMART'}`)
  }, [itemPaymentMethod])

  return (
    <>
      <div
        className={`border ${
          toastMsg ? 'border-[#D71920]' : 'border-[#C8F5FF]'
        } bg-[#C8F5FF]  mb-3 rounded-[16px] duration-1000 overflow-hidden group w-full ${collsap ? 'is-active' : ''} `}
      >
        <div className="flex items-center px-6 py-3 justify-between">
          <div className="flex items-center">
            <div className="">
              <img
                src={require(`@assets/icon/${itemPaymentMethod.icon}.png`)}
                alt="123"
                className="w-[auto] max-w-[40px] h-full"
              />
            </div>
            <div className="pl-3">
              <div className="flex justify-between items-center">
                <p className="text-[#151515] text-[16px] font-[500]">{itemPaymentMethod.title} </p>
              </div>
              <p className="text-[#595959] text-[16px] font-[400]">{itemPaymentMethod.paymentsTitle}</p>
            </div>
          </div>
          <div className="pl-3 flex justify-end items-end">
            <svg
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 h-3 rotate-0 cursor-pointer duration-500 group-[.is-active]:rotate-[180deg]"
              onClick={handleToggleActive}
            >
              <path id="Polygon 4" d="M4.99996 7.62508L9.7631 0.37508H0.236816L4.99996 7.62508Z" fill="black" />
            </svg>
          </div>
        </div>
        <>{itemPaymentMethod.id === PaymentMethodKey.VNPAYQR && renderQR()}</>
        <>{itemPaymentMethod.id === PaymentMethodKey.ATM && renderBankCard('bank')}</>
        <>{itemPaymentMethod.id === PaymentMethodKey.VISA && renderBankCard('visa')}</>
        <>{itemPaymentMethod.id === PaymentMethodKey.VNMART && renderWallet()}</>
      </div>
      {toastMsg && !payment && (
        <p className="text-[#FD0606BF] font-[13px] text-right mt-4">Vui lòng chọn hình thức thanh toán.</p>
      )}
    </>
  )
}

export default Payments
