import { detectEnvUat } from '@utils/general'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import { RootState } from 'store'
import { setEduBillDetail, setIsHome, Type_Initstate } from 'store/appSlice'

interface P {
  isFull?: boolean
}

const Header: FC<P> = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app)
  const { edubillDetail } = appState
  const [pathname, setpathname] = useState('')

  useEffect(() => {
    setpathname(window.location.pathname)
  }, [appState.isHome, window.location.pathname])

  const slideImages = [
    {
      url: '60297.jpg',
      caption: 'Slide 1',
    },
    {
      // url: '60297.jpg',
      url: 'image.png',
      caption: 'Slide 2',
    },
  ]

  const handleOnClick = () => {
    const location = window.location.pathname.split('/')

    if (
      (detectEnvUat() &&
        location.slice(0, 6).join('/') ===
          `/uat/organization/${appState.studenInfor?.orgCode}/school/${appState.studenInfor?.schoolCode}`) ||
      location.slice(0, 7).join('/') ===
        `/uat/confirm-payment/organization/${appState.studenInfor?.orgCode}/school/${appState.studenInfor?.schoolCode}`
    ) {
      window.location.replace(`${appState.edubillDetail.schoolUrl + '/#/thanhtoanonline'}`)
    } else if (
      location.slice(0, 5).join('/') ===
        `/organization/${appState.studenInfor?.orgCode}/school/${appState.studenInfor?.schoolCode}` ||
      location.slice(0, 6).join('/') ===
        `/uat/confirm-payment/organization/${appState.studenInfor?.orgCode}/school/${appState.studenInfor?.schoolCode}`
    ) {
      window.location.replace(`${appState.edubillDetail.schoolUrl + '/#/thanhtoanonline'}`)
    } else {
      dispatch(setEduBillDetail(undefined))
      dispatch(setIsHome(true))
      navigate(`${detectEnvUat() ? '/uat' : '/'}`)
    }
  }

  return (
    <>
      <div
        className={`${
          appState.isHome ? 'h-40' : 'h-30'
        } w-full bg-[url('@assets/background/banner.png')] bg-cover bg-no-repeat`}
      >
        <div className="px-2 justify-between  py-3.5 flex">
          <p
            className="md:text-[22px] font-bold cursor-pointer flex justify-center max-w-[350px] md:max-w-[530px] text-[16px] items-center md:items-center"
            onClick={() => {
              handleOnClick()
            }}
            style={{
              color: '#045993',
            }}
          >
            {!appState.isHome && (
              <img src={edubillDetail?.avatarUrl} alt="" className="w-[76px] h-[76px] mr-3 object-contain" />
            )}
            <span className="whitespace-break-spaces">
              <span
                className=""
                dangerouslySetInnerHTML={{
                  __html: !appState.isHome ? edubillDetail?.schoolName : 'AQ BILL GATEWAY',
                }}
              />

              {appState.isHome && (
                <span className="font-bold cursor-pointer flex justify-center text-[#045993]">
                  Cổng dịch vụ thu phí trường học EduBill
                </span>
              )}
            </span>
          </p>
          <div>
            {appState.studenInfor && (
              <div className="hidden md:block">
                <p className="text-[#595959]">
                  Tên học viên:{' '}
                  <span className="text-[#151515] text-[16px] font-[500]">{appState.studenInfor?.HoTen}</span>
                </p>
                <p className="text-[#595959]">
                  Mã học viên:{' '}
                  <span className="text-[#151515] text-[16px] font-[500]">{appState.studenInfor?.MaSV}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {!appState.isHome && pathname !== '/payment-result' && (
        <div className="slide-container">
          <Slide>
            {slideImages.map((slideImage, index) => (
              <div key={index}>
                <img
                  className="xl:h-[220px] md:h-[150px] lg:h-[270px] 2xl:h-[350px] h-[130px]"
                  src={require(`@assets/background/${slideImage.url}`)}
                  alt=""
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundSize: 'cover',
                    // height: '200px',
                    width: '100%',
                    opacity: '0.7',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ))}
          </Slide>
        </div>
      )}
    </>
  )
}
export default Header
