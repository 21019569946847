import React from 'react'

const IconError = () => {
  return (
    <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_47_116)">
        <path
          d="M75.0602 36.0602C73.4365 36.0602 72.1206 37.3762 72.1206 39C72.1206 57.2634 57.2634 72.1206 39 72.1206C20.7376 72.1206 5.8794 57.2634 5.8794 39C5.8794 20.7376 20.7376 5.8794 39 5.8794C40.6237 5.8794 41.9398 4.56346 41.9398 2.93978C41.9398 1.31595 40.6237 0 39 0C17.4952 0 0 17.4952 0 39C0 60.5039 17.4952 78 39 78C60.5039 78 78 60.5039 78 39C78 37.3763 76.6841 36.0602 75.0602 36.0602Z"
          fill="#D71920"
        />
        <rect
          x="21.1152"
          y="23.6509"
          width="5"
          height="47"
          rx="2.5"
          transform="rotate(-45 21.1152 23.6509)"
          fill="#D71920"
        />
        <rect
          x="54.3496"
          y="20.1152"
          width="5"
          height="47"
          rx="2.5"
          transform="rotate(45 54.3496 20.1152)"
          fill="#D71920"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_116">
          <rect width="78" height="78" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconError
