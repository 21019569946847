import React from 'react'

interface P {
  handleCancel: () => void
}

const IconCancel = ({ handleCancel }: P) => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 54 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
      onClick={handleCancel}
    >
      <g id="Group 177">
        <rect
          id="Rectangle 51"
          x="12.2656"
          y="15.4941"
          width="4.04255"
          height="38"
          rx="2.02128"
          transform="rotate(-45 12.2656 15.4941)"
          fill="#D71920"
        />
        <rect
          id="Rectangle 52"
          x="39.1357"
          y="12.636"
          width="4.04255"
          height="38"
          rx="2.02128"
          transform="rotate(45 39.1357 12.636)"
          fill="#D71920"
        />
      </g>
    </svg>
  )
}

export default IconCancel
