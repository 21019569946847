import React, { useEffect, useState } from 'react'
import { TypeMethodPayment } from '../../../types/general'

interface P {
  toastMsg: boolean
  item: TypeMethodPayment
  child: TypeMethodPayment
  itemPaymentMethod: TypeMethodPayment
  setChild: React.Dispatch<React.SetStateAction<TypeMethodPayment>>
  setItemPaymentMethod: React.Dispatch<React.SetStateAction<TypeMethodPayment>>
}

const MethodPayment = ({ item, setItemPaymentMethod, itemPaymentMethod, toastMsg, setChild, child }: P) => {
  const [collsap, setCollsap] = useState(false)

  const handleToggleActive = () => {
    setCollsap(!collsap)
  }

  useEffect(() => {
    itemPaymentMethod && itemPaymentMethod.id !== item.id && setCollsap(false)
    itemPaymentMethod && itemPaymentMethod.id === item.id && setCollsap(true)
  }, [itemPaymentMethod])

  return (
    <>
      {item.status && item?.sign && (
        <>
          <div
            key={item.id}
            className={`border ${
              item.id === itemPaymentMethod?.id
                ? 'border-[#C8F5FF] bg-[#C8F5FF]'
                : toastMsg
                ? 'border-[#D71920]'
                : 'border-[#D1D1D1]'
            } mb-3 rounded-[16px] duration-500 overflow-hidden group xl:w-[510px] ${collsap ? 'is-active' : ''}`}
            onClick={() => {
              setItemPaymentMethod(item)
            }}
          >
            <div className="flex items-center md:px-6 md:py-3 px-2 py-2 justify-between">
              <div className="flex items-center">
                <div className="">
                  <img
                    src={require(`@assets/icon/${item.icon}.png`)}
                    alt="123"
                    className="w-[auto] max-w-[40px] h-full"
                  />
                </div>
                <div className="pl-3">
                  <div className="flex justify-between items-center">
                    <p className="text-[#151515] text-[16px] font-[500]">{item.title} </p>
                  </div>
                  <p className="text-[#595959] text-[16px] font-[400] w-48">{item.description}</p>
                </div>
              </div>
              <div className="pl-3 flex justify-end items-end">
                {item.id === itemPaymentMethod?.id ? (
                  <svg
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3 rotate-0 cursor-pointer duration-500 group-[.is-active]:rotate-[180deg]"
                    onClick={handleToggleActive}
                  >
                    <path id="Polygon 4" d="M4.99996 7.62508L9.7631 0.37508H0.236816L4.99996 7.62508Z" fill="black" />
                  </svg>
                ) : (
                  <input
                    type="radio"
                    className="form-radio"
                    value={item.id}
                    checked={item.id === itemPaymentMethod?.id}
                  />
                )}
              </div>
            </div>
            {item?.child && item.child?.length !== 0 && (
              <>
                {item.child.map((e) => (
                  <div
                    className={`overflow-hidden px-6 duration-500 max-h-0 group-[.is-active]:max-h-[200px] bg-[#F4F4F4] ${
                      collsap ? 'pt-4 last:pb-4' : ''
                    }`}
                    key={e.id}
                    onClick={() => {
                      setChild(e)
                      // setItemPaymentMethod(e)
                    }}
                  >
                    <div
                      className={`flex items-center h-[66px] rounded-[16px] bg-white border p-3 ${
                        e.id === itemPaymentMethod?.id ? 'border-[#C8F5FF' : 'border-[#D1D1D1]'
                      } `}
                    >
                      <div className="">
                        <img src={require(`@assets/icon/${e.icon}.png`)} className="w-[auto] max-w-[40px] h-full" />
                      </div>
                      <div className="pl-3">
                        <p className="text-[#151515] text-[16px] font-[500] w-48">{e.title}</p>
                        <p className="text-[#595959] text-[16px] font-[400] w-48">{e.description}</p>
                      </div>
                      <div className="pl-3 flex justify-end items-end w-full">
                        <input type="radio" className="form-radio" value={e.id} checked={e.id === child?.id} />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          {toastMsg && !itemPaymentMethod && (
            <p className="text-[#FD0606BF] font-[13px] text-right mb-2">Vui lòng chọn phương thức thanh toán.</p>
          )}
        </>
      )}
    </>
  )
}

export default MethodPayment
