import React, { FC } from 'react'
interface P {
  visible?: boolean
}

const Empty: FC<P> = ({ visible }: P) => {
  return (
    <div className="flex justify-center flex-col  mt-24">
      {visible && <div className="text-[#151515] text-[20px] font-[500] text-left mb-14">Lịch sử giao dịch</div>}
      <svg
        width="54"
        height="54"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mx-auto"
      >
        <path d="M41.625 41.625L49.5 49.5" stroke="#ADADAD" strokeWidth="3" strokeLinecap="round" />
        <path
          d="M15.1875 7.35959C18.3315 5.5409 21.9817 4.5 25.875 4.5C37.6801 4.5 47.25 14.0699 47.25 25.875C47.25 37.6801 37.6801 47.25 25.875 47.25C14.0699 47.25 4.5 37.6801 4.5 25.875C4.5 21.9817 5.5409 18.3315 7.35959 15.1875"
          stroke="#ADADAD"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
      <p className="mx-auto text-[#595959] text-[20px] font-[500] mt-3">Chúng tôi không tìm thấy kết quả nào</p>
      <p className="text-[rgb(38,67,165)] text-[16px] font-[400] mt-3 text-center">
        Đảm bảo thông tin mã học viên và CCCD là chính xác
      </p>
    </div>
  )
}

export default Empty
