import React from 'react'

const IconCheck = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z"
        fill="#4FB3DA"
      />
      <path d="M3.5 7L6 9.5L11 5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default IconCheck
