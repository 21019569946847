import Empty from '@components/Empty'
import React, { FC } from 'react'

export interface DataType {
  key: React.ReactNode
  name: string
  noidung: string
  daThu: string
  maSinhVien: string
  truong: string
  hinhThucThanhToan: string
  thoiGian: string
  children?: DataType[]
}

interface IProps {
  isFalse: boolean
  valueSearch: string
}

const TableHistory: FC<IProps> = ({ isFalse, valueSearch }: IProps) => {
  return (
    <div className="flex flex-col md:mt-12 mt-2">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8  md:px-6">
        {isFalse && valueSearch && (
          <div className="md:mt-[150px] mt-[30%]">
            <Empty />
          </div>
        )}
      </div>
    </div>
  )
}

export default TableHistory
