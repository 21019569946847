import { Modal } from 'antd'
import React, { FC } from 'react'

interface IProps {
  img?: string
  isModalOpen: boolean
  setIsModalOpen: (value: boolean) => void
}

const ModalSHB: FC<IProps> = ({ isModalOpen, img, setIsModalOpen }: IProps) => {
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Modal
        title={<></>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<></>}
        className="p-0 custom-modal rouder-31"
      >
        <div className="relative bg-white rounded-[30px] pt-4">
          <img src={`data:image/png;base64, ${img}`} alt="" className="mx-auto w-full" />
        </div>
      </Modal>
    </>
  )
}
export default ModalSHB
