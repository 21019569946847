import * as Sentry from '@sentry/browser'
import { message } from 'antd'
import { useDispatch } from 'react-redux'
import { setListEduBillDetail, setLoading } from 'store/appSlice'
import { NetWorkService, validResponse } from './API'
import { CODE_SUCCESS, listSchool } from './constan'
import { detectEnvUat } from './general'

const UseGetEdubillDetail = () => {
  const dispatch = useDispatch()
  const { Get } = NetWorkService

  const handleGetBillList = async () => {
    try {
      dispatch(setLoading(true))
      const isUat = detectEnvUat()

      const url = '/api/school/all'
      const res: any = await Get({
        url,
      })

      if (res && validResponse(res) && res.code === CODE_SUCCESS && res.data.data?.success !== false) {
        dispatch(setListEduBillDetail([...res.data.data.filter((e) => (isUat ? !e.golive : e.golive)), listSchool[2]]))
        // dispatch(setListEduBillDetail(listSchool))
      } else {
        message.error(res.data.data.message)
        Sentry.captureMessage(res.data.message)
      }
    } catch (error) {
      message.error('Có lỗi xảy ra')
      console.log(error)
      Sentry.captureException(error)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return { handleGetBillList }
}

export default UseGetEdubillDetail
