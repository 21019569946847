import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import appSlice from './appSlice'

const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    app: appSlice,
  },
})

setupListeners(store.dispatch)

export { store }
export type RootState = ReturnType<typeof store.getState>
