import { IDynamicObject, IEdubillDetail, IGenericObject, SCHOOL_CODE } from '../types'
import { KEY_SCHOOL, SchoolCodeEnum, SCHOOLL_INFOR } from './constan'

export const listToObjMap = <T>(list: IDynamicObject[], key: string): IGenericObject<T> => {
  const obj: IGenericObject<T> = {}

  list.forEach((item: IDynamicObject) => {
    obj[item[key]] = item as T
  })

  return obj
}

export const objMapToList = <T>(obj: IGenericObject<T>): T[] => {
  // this function will ignore the key and only push the value to the list
  // { key1: value1, key2: value2 } => [value1, value2]

  const list: T[] = []

  Object.keys(obj).forEach((key: string) => {
    list.push(obj[key])
  })

  return list
}

export const keyObjMapToList = <T>(
  obj: IGenericObject<T>,
  keyName: string,
  valueName: string,
): { [key: string]: IGenericObject<T> }[] => {
  // this function will push the key and value to the list
  // keyName = 'key', valueName = 'value'
  // { key1: value1, key2: value2 } => [{ key: key1, value: value1 }, { key: key2, value: value2 }]

  const list = []

  Object.keys(obj).forEach((key: string) => {
    list.push({
      [keyName]: key as string,
      [valueName]: obj[key] as T,
    })
  })

  return list
}

// sét giá trị của một cookie nhất định
export const saveValueInCookie = (key: string, value: string) => {
  const now = new Date()
  const expires = new Date(now.getTime() * 60 * 60 * 1000) // thêm 1 giờ
  document.cookie = `${key}=${value}; expires=${expires.toUTCString()};`
}

// đọc giá trị của một cookie nhất đinh
export const readValueCookie = (value: string) => {
  const cookies = document.cookie.split(';')
  const cookieObj = {}
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim().split('=')
    cookieObj[cookie[0]] = cookie[1]
  }
  return cookieObj[value]
}

export const handleSetSchool = (params, dispatch, setSchoolCode) => {
  if (params?.id) {
    params?.id && dispatch(setSchoolCode(undefined))
    return undefined
  }
  const listKey = [SchoolCodeEnum.DHHV, SchoolCodeEnum.TNUT, KEY_SCHOOL.HV, KEY_SCHOOL.TN]
  let school = undefined
  listKey.forEach((e) => {
    if (params.schoolCode.includes(e)) {
      school = e
    }
  })
  if (school === KEY_SCHOOL.TN) {
    school = listKey[1]
  } else {
    school = listKey[0]
  }
  params.studentID && dispatch(setSchoolCode(school))
  return school
}

export const formatMoney = (money: number) => {
  let _money = String(money)
  _money = _money.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  return _money
}

export const schoolInfor = (school: SCHOOL_CODE['CODE']) => {
  if (!school) {
    return SCHOOLL_INFOR.hcmiu
  }

  return school === SchoolCodeEnum.DHHV ? SCHOOLL_INFOR.dhhv : SCHOOLL_INFOR.tnut
}

export const detectEnvUat = () => {
  const ENV = window.location.pathname
  ENV.includes('uat') ? localStorage.setItem('uat', 'YES') : localStorage.setItem('uat', 'NO')
  return ENV.includes('uat')
}

export const detectWebview = () => {
  const navigator = window.navigator as any
  const userAgent = navigator.userAgent
  const normalizedUserAgent = userAgent.toLowerCase()
  const standalone = navigator.standalone

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  const isAndroid = /android/.test(normalizedUserAgent)
  const isSafari = /safari/.test(normalizedUserAgent)
  const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)

  const osText = isIos ? 'iOS' : isAndroid ? 'Android' : 'Other'
  const webviewText = isWebview ? 'Yes' : 'No'

  return {
    OS: osText,
    Webview: webviewText,
  }
}

export const handleViewTitle = (school: string, name: string, edubillDetail: IEdubillDetail) => {
  switch (school) {
    case SchoolCodeEnum.HCMUI:
      return name
    case SchoolCodeEnum.DHHV:
      return edubillDetail ? edubillDetail?.schoolName : name
    case SchoolCodeEnum.TNUT:
      return edubillDetail ? edubillDetail?.schoolName : name

    default:
      return name
  }
}

export const handleViewImg = (school: string, edubillDetail: IEdubillDetail) => {
  switch (school) {
    case SchoolCodeEnum.HCMUI:
      return require('@assets/background/logo.png')
    case SchoolCodeEnum.DHHV:
      return edubillDetail ? edubillDetail?.avatarUrl : require('@assets/background/DHHV.png')
    case SchoolCodeEnum.TNUT:
      return edubillDetail ? edubillDetail?.avatarUrl : require('@assets/background/TNUT.png')

    default:
      return ''
  }
}
