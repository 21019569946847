import Button from '@components/Button'
import { detectEnvUat, detectWebview } from '@utils/general'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'store'
import { setDevice, setEduBillDetail, setIsHome, setLoading, setSchoolCode, Type_Initstate } from 'store/appSlice'
import Icon from './components/Icon'
import IconError from './components/IconError'

interface IPaymentResult {
  status?: string
  orgCode?: string
  schoolCode?: string
  secretCode?: string
}

const RES_CODE = 'vpc_TxnResponseCode'
const PaymentResult = () => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isWebview = detectWebview().Webview
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app)

  const [localValue, setLocalValue] = useState<IPaymentResult | undefined>(undefined)
  const [countDown, setCountDown] = useState(2000)

  useEffect(() => {
    const str = window.location.search.slice(1)
    const keyValuePairs = str.split('&')

    if (keyValuePairs.length === 4) {
      const result: IPaymentResult = {}
      for (const pair of keyValuePairs) {
        const [key, value] = pair.split('=')
        result[key] = value
      }

      setLocalValue({ ...result, secretCode: str.split('=')[str.split('=').length - 1] })
      // check result cho onpelay
    } else if (keyValuePairs.length >= 4) {
      const result: IPaymentResult = {
        orgCode: params.orgCode,
        schoolCode: params.schoolCode,
        secretCode: params.studentID,
        status: keyValuePairs.find((e) => e.includes(RES_CODE))?.split('=')[1] === '0' ? 'success' : 'fail',
      }

      setLocalValue({ ...result })
    } else {
      setLocalValue(undefined)
    }

    dispatch(setDevice(isWebview === 'Yes' ? 'app' : 'web'))
  }, [])

  useEffect(() => {
    dispatch(setSchoolCode(undefined))
    dispatch(setIsHome(true))
  }, [localValue])

  useEffect(() => {
    dispatch(setLoading(true))

    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 100)
      }, 100)
    } else {
      dispatch(setLoading(false))
    }
  }, [countDown])

  useEffect(() => {
    if (appState?.listEdubillDetail.length !== 0) {
      const edubillDetail = appState?.listEdubillDetail.find((e) => e.orgCode === params.orgCode)
      dispatch(setEduBillDetail(edubillDetail))
    }
  }, [appState?.listEdubillDetail, params.orgCode])

  const handleNavigate = () => {
    if (localValue && localValue?.orgCode) {
      window.location = `${detectEnvUat() ? '/uat' : ''}/organization/${localValue.orgCode}/school/${
        localValue.schoolCode
      }/student/${localValue.secretCode}` as any
    } else {
      appState.device !== 'web'
        ? (window.location = `${detectEnvUat() ? '/uat' : ''}/organization/${localValue?.orgCode}/school/${
            localValue?.schoolCode
          }/student/${localValue?.secretCode}` as any)
        : navigate(`${detectEnvUat() ? '/uat' : '/'}`)
    }
  }

  return (
    <>
      {countDown === 0 && (
        <div
          className="flex justify-center items-center mt-[10%] bg-white p-10 md:p-14 md:pb-8 flex-col rounded-[20px] w-fit mx-auto md:max-w-[500px]"
          style={{
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
          }}
        >
          {localValue && localValue?.status === 'success' ? <Icon /> : <IconError />}
          {localValue && localValue?.status === 'success' ? (
            <div className="text-[#113D6B] font-[700] text-[20px] py-3">Thanh toán thành công</div>
          ) : (
            <div className="text-[#D71920] font-[700] text-[20px] py-3">Giao dịch thất bại</div>
          )}
          <div className="text-[#788AA2] font-[500] text-[14px] leading-[17px] text-center mb-10">
            {localValue && localValue?.status === 'success'
              ? 'Chúc mừng bạn đã thanh toán thành công. Vui lòng trở lại trang chủ đăng nhập để sử dụng dịch vụ'
              : 'Bạn đã thanh toán không thành công.Vui lòng trở lại trang chủ đăng nhập để sử dụng dịch vụ'}
          </div>
          <Button
            text="Xác nhận"
            onClick={() => {
              handleNavigate()
            }}
          />
        </div>
      )}
    </>
  )
}

export default PaymentResult
