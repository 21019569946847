import useWindowDimensions from '@hooks/useWindowDimensions'
import * as Sentry from '@sentry/browser'
import { NetWorkService, validResponse } from '@utils/API'
import { CODE_SUCCESS } from '@utils/constan'
import { formatMoney } from '@utils/general'
import { message, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'store'
import { setLoading, Type_Initstate } from 'store/appSlice'
import { DataType } from '..'
import { ITransactionHistory } from '../../../types/general'
import DetailItemHistory from './DetailItemHistory'

interface IProps {
  isModalOpen: boolean
  bill: DataType | undefined
}

const ModalHistory = ({ bill, isModalOpen }: IProps) => {
  const dispatch = useDispatch()
  const { Get } = NetWorkService
  const params = useParams()
  const navigate = useNavigate()
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app)
  const [transactionHistory, setTransactionHistory] = useState<ITransactionHistory[]>([])
  const [detailTransaction, setDetailTransaction] = useState<ITransactionHistory[]>([])
  const { width } = useWindowDimensions()
  const isMobile = useMemo(() => width < 640, [width]) // is mobile size if width < 640

  const handleGetBillList = async () => {
    try {
      dispatch(setLoading(true))
      const url = `/api/organization/${params.orgCode}/school/${params.schoolCode}/payment-history/${appState.studenInfor.MaSV}?so_phieu_bao[eq]=${bill.name}`

      const res = await Get<any>({
        url: url,
      })

      if (res && validResponse(res) && res.code === CODE_SUCCESS && res.data.data.success !== false) {
        setTransactionHistory(res.data.data.filter((e) => e).map((e) => ({ ...e, key: e._id })))
      } else {
        const msgErr = res && validResponse(res) ? res.data.message : 'Có lỗi xảy ra'
        message.error(msgErr)
        Sentry.captureMessage(msgErr)
        navigate('/error')
      }
    } catch (error) {
      message.error('Có lỗi xảy ra')
      Sentry.captureException(error)
      navigate('/error')
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    appState.studenInfor && bill && isModalOpen && handleGetBillList()
    isModalOpen && setDetailTransaction([])
  }, [appState.studenInfor, isModalOpen, bill])

  const columns: ColumnsType<ITransactionHistory> = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'TransactionNo',
      key: 'TransactionNo',
      align: 'right',
      render: (value) => <div className="text-right font-[400]">{value}</div>,
    },
    {
      title: 'Giá trị GD',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (value) => <div className="text-right font-[400]">{formatMoney(value)}</div>,
    },
    {
      title: 'TGTT',
      dataIndex: 'paymentChannel',
      key: 'paymentChannel',
      align: 'right',
      render: (value) => <div className="text-right font-[400]">{value}</div>,
    },
    {
      title: 'PTTT',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      align: 'right',
      render: (value) => <div className="text-right font-[400]">{value}</div>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'right',
      render: (value) => (
        <>
          {value === 1 ? (
            <p className="text-[#5FAD67] font-[500]">Thành công</p>
          ) : value === 2 ? (
            <p className="text-[#F3C262] font-[500]">Đang chờ xử lý</p>
          ) : (
            <p className="text-[#FF2323] font-[500]">Thất bại</p>
          )}
        </>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'createDate',
      key: 'createDate',
      align: 'right',
      render: (value) => <div className="text-right font-[400]">{moment(value).format('HH:mm:ss DD/MM/YYYY')}</div>,
    },
  ]

  const columnsDetail: ColumnsType<ITransactionHistory> = [
    {
      title: 'Số phiếu thu',
      dataIndex: 'so_phieu_bao',
      key: 'so_phieu_bao',
      align: 'right',
      render: (value) => <div className="text-right font-[400]">{value}</div>,
      width: '20%',
    },
    {
      title: 'Nội dung',
      dataIndex: 'noi_dung',
      key: 'noi_dung',
      align: 'right',
      render: (value) => (
        <div className="text-right color-[#151515] font-[400]" dangerouslySetInnerHTML={{ __html: value }} />
      ),
      width: '80%',
    },
  ]

  const keys = {
    TransactionNo: 'Mã giao dịch',
    amount: 'Giá trị GD',
    paymentChannel: 'TGTT',
    paymentMethod: 'PTTT',
    status: 'Trạng thái',
    createDate: 'Thời gian',
  }

  return isMobile ? (
    <div className="bg-[#F3F7FC] rounded-[8px] p-[10px] w-full">
      {transactionHistory && transactionHistory.length > 0 ? (
        transactionHistory.map((item, index) => (
          <div key={index}>
            <DetailItemHistory item={item} keys={keys} />
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  ) : (
    <>
      <div className="flex items-center mt-5">
        <p className="text-[#151515] font-[500] text-[18px]">Lịch sử giao dịch</p>
      </div>
      <div className="overflow-x-auto">
        <Table
          columns={columns}
          dataSource={transactionHistory}
          pagination={false}
          scroll={{ x: '900px' }}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
              setDetailTransaction(selectedRows)
            },
          }}
        />
        <div className="w-full h-[2px] bg-[#E9E9E9] my-5 mt-10"></div>

        {detailTransaction.length ? (
          <>
            <p className="text-[#151515] font-[500] text-[18px]">Chi tiết</p>
            <Table columns={columnsDetail} dataSource={detailTransaction} pagination={false} scroll={{ x: '900px' }} />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default ModalHistory
